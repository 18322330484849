export const DISPLAY_POPUP = 'DISPLAY_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';

export const closePopup = (index) => {
  return {
    type: CLOSE_POPUP,
    payload: {
      index
    }
  };
};

export const createBillPopup = bill => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-bill',
        component: 'EditBillPopup',
        bill
      }
    }
  };
};

export const createCommissionPopup = commission => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-commission',
        component: 'EditCommissionPopup',
        commission
      }
    }
  };
};

export const createProductionPopup = (order_id, shipping, purchase_orders) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'regenerate-purchase-orders',
        component: 'RegeneratePurchaseOrdersPopup',
        order_id,
        shipping,
        purchase_orders
      }
    }
  };
};

export const createHeaderPopup = (job_id, header_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'replace-header',
        component: 'EditHeaderPopup',
        job_id,
        header_id
      }
    }
  };
};

export const createSelectShopHeaderPopup = (shop_id, public_view_template_id, name) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-shop-template-header',
        component: 'SelectShopHeaderPopup',
        shop_id,
        public_view_template_id,
        name
      }
    }
  };
};

export const createSelectFeaturedShopItemPopup = (shop_id, public_view_template_id, name) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-featured-shop-item-template-header',
        component: 'SelectFeaturedShopItemPopup',
        shop_id,
        public_view_template_id,
        name
      }
    }
  };
};

export const createUpgradeEstimatePopup = (job_id, job_number, order_id, form_number) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'upgrade-estimate',
        component: 'UpgradeEstimatePopup',
        job_id,
        job_number,
        order_id,
        form_number
      }
    }
  };
};

export const createUpgradePresentationPopup = (job_id, job_number, order_id, form_number, target_type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'upgrade-presentation',
        component: 'UpgradePresentationPopup',
        job_id,
        job_number,
        order_id,
        form_number,
        target_type
      }
    }
  };
};

export const createArtworkPopup = (artwork, locked, copy, account_id, account_type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-artwork',
        component: 'EditArtworkPopup',
        artwork,
        locked,
        copy,
        account_id,
        account_type
      }
    }
  };
};

export const createArtworkLibraryPopup = (register, item, account_id, account_type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'artwork-library',
        component: 'ArtworkLibrary',
        register,
        item,
        account_id,
        account_type
      }
    }
  };
};

export const createCopyOrderPopup = (order, upgrade_type = null, shop_type = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'copy-order',
        component: 'CopyOrderPopup',
        order,
        upgrade_type,
        shop_type
      }
    }
  };
};

export const createCreateProjectAndOrderPopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'create-project',
        component: 'CreateProjectAndOrderPopup'
      }
    }
  };
};

export const createEditServicePopup = (item_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-service',
        component: 'EditServicePopup',
        item_id
      }
    }
  };
};

export const createEditArtworkPOPopup = (item_id, purchase_order_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-artwork-po',
        component: 'EditArtworkPOPopup',
        item_id,
        purchase_order_id
      }
    }
  };
};

export const createUploadProductImagePopup = (item_id, account_id, account_type, closeOnLoad = true) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'upload-product-image',
        component: 'UploadProductImagePopup',
        item_id,
        account_id,
        account_type,
        closeOnLoad,
      }
    }
  };
};

export const createSelectVariantPopup = (products, order_id, parent_type, product_source, target_type, suggestion_id, event, display_order = null, success_callback = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-variant',
        component: 'SelectVariantPopup',
        products,
        order_id,
        parent_type,
        product_source,
        target_type,
        suggestion_id,
        event,
        display_order,
        success_callback
      }
    }
  };
};

export const createSelectFobPopup = (product, order_id, currency_id, parent_type, product_source, target_type, suggestion_id, event, display_order = null, success_callback = null) => {
  if ('OPTION' === parent_type) {
    return {
      type: DISPLAY_POPUP,
      payload: {
        insertBefore: 'SelectFobPopup',
        popup: {
          type: 'select-reference-product',
          component: 'SelectReferenceProductPopup',
          product,
          order_id,
          currency_id,
          parent_type,
          product_source,
          target_type,
          suggestion_id,
          event,
          display_order,
          success_callback
        }
      }
    };
  }
  return {
    type: DISPLAY_POPUP,
    payload: {
      insertBefore: 'SelectFobPopup',
      popup: {
        type: 'select-fob',
        component: 'SelectFobPopup',
        product,
        order_id,
        currency_id,
        parent_type,
        product_source,
        target_type,
        suggestion_id,
        event,
        display_order,
        success_callback
      }
    }
  };
};

export const createSelectReferenceProductPopup = (product, order_id, currency_id, parent_type, product_source, target_type, suggestion_id, event, display_order = null, success_callback = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      insertBefore: 'SelectReferenceProductPopup',
      popup: {
        type: 'select-reference-product',
        component: 'SelectReferenceProductPopup',
        product,
        order_id,
        currency_id,
        parent_type,
        product_source,
        target_type,
        suggestion_id,
        event,
        display_order,
        success_callback
      }
    }
  };
};

export const createSelectCollectionItemPopup = (collection, order_id, insertIndex = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-collection-item',
        component: 'SelectCollectionItemPopup',
        collection,
        order_id,
        insertIndex
      }
    }
  };
};

export const createSelectDecorationProductPopup = (order_number, order_type, item_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-decoration-product',
        component: 'SelectDecorationProductPopup',
        order_number,
        order_type,
        item_id
      }
    }
  };
};

export const createSelectDecorationLocationPopup = (item_locations, item_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-decoration-location',
        component: 'SelectDecorationLocationPopup',
        item_locations,
        item_id
      }
    }
  };
};

export const createAddProductToOrderPopup = (order_id, parent_type, parent_id, suggestion_id, event) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'add-product-to-order',
        component: 'AddProductToOrderPopup',
        order_id,
        parent_type,
        parent_id,
        suggestion_id,
        event
      }
    }
  };
};

export const createCheckInventoryLevelPopup = (sku, api_name, item_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'check-inventory-level',
        component: 'CheckInventoryLevelPopup',
        sku,
        api_name,
        item_id
      }
    }
  };
};

export const createSelectTaxPopup = (order_id, tax_id, possible_taxes) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-tax-popup',
        component: 'SelectTaxPopup',
        order_id,
        tax_id,
        possible_taxes
      }
    }
  };
};

export const createValidateConfirmationPopup = (method, params_arr, message = 'You Sure?', description, redirect) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'validate-confirmation',
        component: 'ValidateConfirmationPopup',
        method,
        params_arr,
        message,
        description,
        redirect
      }
    }
  };
};

export const createConfirmationForDeletePopup = (method, params_arr,element,element_name,redirect) => {
  return {
    type: DISPLAY_POPUP,
    payload:{
      popup: {
        type:'validate-confirmation',
        component: 'ConfirmationForDeletePopup',
        method,
        params_arr,
        element,
        element_name,
        redirect
      }
    }
  };
};

export const createSupportPopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'support',
        component: 'SupportPopup',
      }
    }
  };
};

export const createAddNewProductPopup = (order_id, order_type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'add-new-product',
        component: 'AddNewProductPopup',
        order_id,
        order_type
      }
    }
  };
};

export const createAddTemplatePopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'add-template',
        component: 'AddTemplatePopup'
      }
    }
  };
};

export const createNotePopup = (purchase_order_id, job_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'note',
        component: 'NotePopup',
        purchase_order_id,
        job_id
      }
    }
  };
};

export const createCheckOrderStatusPopup = (purchase_order_id, division_id, form_number, date_shipped, onUpdate) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
        popup: {
            type: 'check-order-status',
            component: 'CheckOrderStatusPopup',
            purchase_order_id,
            division_id,
            form_number,
            date_shipped,
            onUpdate
        }
    }
  };
};

export const createAddAddressPopup = (order_id=null, field=null, previous_value=null, parent_id, parent_type, shop_id=null, shop_parent_type=null, address_type=null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'address',
        component: 'AddAddressPopup',
        order_id,
        field,
        previous_value,
        address: {
          parent_id,
          parent_type
        },
        shop_id,
        shop_parent_type,
        address_type
      }
    }
  };
};

export const createEditAddressPopup = (address_id, psEligible, suggestion) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-address',
        component: 'AddAddressPopup',
        address_id,
        psEligible,
        suggestion
      }
    }
  };
};

export const createAddContactPopup = (order_id=null, account_id, field=null, previous_value=null, parent_id, parent_type, shop_id=null, shop_parent_type=null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'contact',
        component: 'AddContactPopup',
        order_id,
        account_id,
        field,
        previous_value,
        contact: {
          parent_id,
          parent_type
        },
        shop_id,
        shop_parent_type
      }
    }
  };
};

export const createAddSupplierPopup = (redirect=false) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'add-supplier',
        component: 'AddSupplierPopup',
        redirect
      }
    }
  };
};

export const createSpinnerPopup = (message, useV2 = false) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'spinner',
        component: 'SpinnerPopup',
        message,
        useV2,
      }
    }
  };
};

export const createNoticePopup = message => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'notice-popup',
        component: 'NoticePopup',
        message
      }
    }
  };
};

export const createErrorPopup = message => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'error-popup',
        component: 'ErrorPopup',
        message
      }
    }
  };
};

export const createErrorAlertPopup = (message, onClose=null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'error-popup',
        component: 'ErrorAlertPopup',
        message,
        onClose,
      }
    }
  };
};

export const createTourMilestonePopup = (milestone, next) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'tour-milestone',
        component: 'TourMilestonePopup',
        milestone,
        next
      }
    }
  };
};

export const createAddYouTubePopup = (video_id, milestone, next) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'add-youtube',
        component: 'AddYouTubePopup',
        video_id,
        milestone,
        next
      }
    }
  };
};

export const createInviteColleaguesPopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'invite-colleagues',
        component: 'InviteColleaguesPopup'
      }
    }
  };
};

export const createAddToQuotePopup = (currentItemId, currentItemType) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'add-to-quote',
        component: 'AddToQuotePopup',
        currentItemId,
        currentItemType
      }
    }
  };
};

export const createCartSummaryPopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'cart-summary',
        component: 'CartSummaryPopup',
      }
    }
  };
};

export const createCopyToProjectPopup = (collection_id, items) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'copy-to-project',
        component: 'CopyToProjectPopup',
        collection_id,
        items
      }
    }
  };
};

export const createColorSizeSubsetPopup = (item_id, product_id, params) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'color-size-subset',
        component: 'ColorSizeSubsetPopup',
        item_id,
        product_id,
        params
      }
    }
  };
};

export const createSkuAxesSubsetPopup = (item_id, product_id, params, sku, api_name) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'sku-axes-subset',
        component: 'SkuAxesSubsetPopup',
        item_id,
        product_id,
        params,
        sku,
        api_name,
      }
    }
  };
};

export const createUpdateNetCostPopup = (item, order_currency_id, locked) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'update-cost',
        component: 'UpdateNetCostPopup',
        item,
        order_currency_id,
        locked
      }
    }
  };
};

export const createEmailMessagePopup = (message,index = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'email-message',
        component: 'EmailMessagePopup',
        message,
        displayIndex:index
      }
    }
  };
};

export const createSelectImagePopup = (order_id, images, selected_id, display_order) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      type: 'select-image',
      component: 'SelectImagePopup',
      order_id,
      images,
      selected_id,
      display_order
    }
  }
});

export const createSelectItemImagesPopup = (item_id, account_id, account_type, locked, props={}) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      type: 'select-item-images',
      component: 'SelectItemImagesPopup',
      item_id,
      account_id,
      account_type,
      locked,
      ...props,
    }
  }
});

export const createSelectCollectionSupplierPopup = suppliers => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      type: 'select-collection-supplier',
      component: 'SelectCollectionSupplierPopup',
      suppliers
    }
  }
});

export const createSelectCollectionImagesReminderPopup = collection => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      type: 'select-collection-images-reminder',
      component: 'SelectCollectionImagesReminderPopup',
      collection
    }
  }
});

export const createCommentPopup = (options) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'CommentPopup',
        options,
      }
    }
  };
};

export const createPasswordPopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'PasswordPopup'
      }
    }
  };
};

export const createContactPopup = (shop_contact, template_color) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'ContactPopup',
        template_color,
        shop_contact
      }
    }
  };
};

export const createIntroPopup = (introduction, shop_contact, template_color, buyInventory = false, shopId = null, isShop = true) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'IntroPopup',
        introduction,
        shop_contact,
        template_color,
        buyInventory,
        shopId,
        isShop,
      }
    }
  };
};

export const createAddShopContactPopup = (shop_id, client_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'AddShopContactPopup',
        shop_id,
        client_id
      }
    }
  };
};

export const createContactListPopup = (parent_id, client_id, type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'ContactListPopup',
        parent_id,
        client_id,
        type
      }
    }
  };
};

export const createAddShopAddressPopup = (shop_id, client_id, address_type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'AddShopAddressPopup',
        shop_id,
        client_id,
        address_type
      }
    }
  };
};

export const createAddressListPopup = (
  parent_id, client_id, address_type, allow_credit_card_checkout=0, zip2tax=0
) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'AddressListPopup',
      parent_id,
      client_id,
      address_type,
      allow_credit_card_checkout,
      zip2tax,
    }
  }
});

export const createJoinOrdersPopup = (shop_id, client_id, order_ids) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'JoinOrdersPopup',
      shop_id,
      client_id,
      order_ids
    }
  }
});

export const createAssignToClientPopup = (order, client_rep_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'AssignToClientPopup',
      order,
      client_rep_id
    }
  }
});

export const createSelectShopArtworkPopup = (artwork_id, tenant_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'SelectShopArtworkPopup',
      artwork_id,
      tenant_id
    }
  }
});

export const SelectShopArtworkPopupV2 = (artwork_id, tenant_id, artwork_image = null) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'SelectShopArtworkPopupV2',
      artwork_id,
      tenant_id,
      artwork_image,
    }
  }
});

export const createCreateClientPopup = (client_name, client_rep_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'CreateClientPopup',
      client_name,
      client_rep_id
    }
  }
});

export const createEditMessagePopup = (message, message_type, job_id,index = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-message',
        component: 'EditMessagePopup',
        message,
        message_type,
        job_id,
        displayIndex : index
      },
    }
  };
};

export const createEditProjectPopup = (job) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-project',
        component: 'EditProjectPopup',
        job
      }
    }
  };
};

export const createEditSalesTargetPopup = (client_rep_id, is_admin) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'sales-target',
        component: 'EditSalesTargetPopup',
        client_rep_id,
        is_admin
      }
    }
  };
};

export const createCreditHoldPopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'credit-hold',
        component: 'CreditHoldPopup'
      }
    }
  };
};

export const createPSTestPopup = (tenant_endpoint) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'ps-test',
        component: 'PSTestPopup',
        tenant_endpoint
      }
    }
  };
};

export const createEditPSEndpointPopup = (tenant_endpoint) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'ps-endpoint',
        component: 'EditPSEndpointPopup',
        tenant_endpoint
      }
    }
  };
};

export const createCopyItemPopup = (item, add_to_order) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'copy-item',
        component: 'CopyItemPopup',
        item,
        add_to_order
      }
    }
  };
};

export const createSelectBreakdownSkuPopup = (product_id, product_sku_id = null, onUpdate = null, onAdd = null, title = null, message = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-breakdown-sku',
        component: 'SelectBreakdownSkuPopup',
        product_id,
        product_sku_id,
        onUpdate,
        onAdd,
        title,
        message
      }
    }
  };
};

export const createSelectBreakdownSkuByColorPopup = (product_id, product_sku_id = null, onUpdate = null, onAdd = null, title = null, message = null, qty = null) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'select-breakdown-sku-by-color',
        component: 'SelectBreakdownSkuByColorPopup',
        product_id,
        product_sku_id,
        onUpdate,
        onAdd,
        title,
        message,
        qty,
      }
    }
  };
};

export const createCheckPSInventoryLevelPopup = (product_id, item_id, sku, api_name) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'check-ps-inventory-level',
        component: 'CheckPSInventoryLevelPopup',
        item_id,
        product_id,
        sku,
        api_name,
      }
    }
  };
};

export const createSelectSkuOrAxisOptionPopup = (product_id, selector, value, item_id, item_retail_adjustment_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup:{
        type: 'select-sku-or-axis-option',
        component: 'SelectSkuOrAxisOptionPopup',
        product_id,
        selector,
        value,
        item_id,
        item_retail_adjustment_id
      }
    }
  };
};

export const createEditTenantPSCredentialPopup = (tenant_id, promo_api_name) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'edit-tenant-ps-credential',
        component: 'EditTenantPSCredentialPopup',
        tenant_id,
        promo_api_name
      }
    }
  };
};

export const createCardConnectPopup = (shop, checkoutTotal, billing_info, shipping_info, breakdowns, artworks, comment, shipping_range_id, customer, order_metadata, applied_discount, customer_po, items_metadata) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'CardConnectPopup',
        shop,
        checkoutTotal,
        billing_info,
        shipping_info,
        breakdowns,
        artworks,
        comment,
        shipping_range_id,
        customer,
        order_metadata,
        applied_discount,
        customer_po,
        items_metadata,
      }
    }
  };
};

export const createMetadataListPopup = (parent_id, metadata_id, parent_type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'MetadataListPopup',
        parent_id,
        metadata_id,
        parent_type
      }
    }
  };
};

export const createPartialPopup = (order_ids) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'PartialPopup',
        order_ids
      }
    }
  };
};

export const createOrderPaymentCreditCardFeePopup = (order, client) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'OrderPaymentCreditCardFeePopup',
        order,
        client
      }
    }
  };
};

export const createAddToOrderPopup = (order_ids, sales_orders, shop_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'AddToOrderPopup',
        order_ids,
        sales_orders,
        shop_id
      }
    }
  };
};

export const createSubmitPOPopup = (purchase_order_id, showEPOPopup) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'SubmitPOPopup',
      purchase_order_id,
      showEPOPopup
    }
  }
});

export const createAddItemLocationPopup = (item_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'AddItemLocationPopup',
      item_id
    }
  }
});

export const createSelectSkuOptionsPopup = (item_id, product_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'SelectSkuOptionsPopup',
      item_id,
      product_id
    }
  }
});

export const createSelectPSSkuOptionsByColorSizePopup = (item_id, product_id, params, selectTabIndex=0, sku, api_name, default_sku_id = null, onUpdate = null) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'SelectPSSkuOptionsByColorSizePopup',
      item_id,
      product_id,
      params,
      selectTabIndex,
      sku,
      api_name,
      default_sku_id,
      onUpdate,
    }
  }
});

export const createNewFeaturePopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        type: 'new-feature',
        component: 'NewFeaturePopup'
      }
    }
  };
};

export const createClientDisplayOptionsPopup = (item_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'ClientDisplayOptionsPopup',
      item_id
    }
  }
});

export const createAddShippingQuotePopup = (job_id, ship_method_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'AddShippingQuotePopup',
      job_id,
      ship_method_id
    }
  }
});

export const createEditCompanyShippingAndBillingPopup = () => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'EditCompanyShippingAndBillingPopup',
      }
    }
  };
};

export const createApplyMatrixToItemPopup = (data={}) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'ApplyMatrixToItemPopup',
        ...data
      }
    }
  };
};

export const createUserPopupPreview = (company_type) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'UserPopupPreview',
        company_type,
      }
    }
  };
};

export const createUploadFilesPopup = (parent_id, parent_type, client_id=null, resku=true, web_upload=false) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'UploadFilesPopup',
        parent_id,
        parent_type,
        client_id,
        resku,
        web_upload
      }
    }
  };
};

export const createDisplayArtworkPopup = (file) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'DisplayArtworkPopup',
        file
      }
    }
  };
};

export const createSelectFilePopup = (register, parent_id, parent_type, client_id, resku=true, props = {}) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'SelectFilePopup',
        register,
        parent_id,
        parent_type,
        client_id,
        resku,
        item_id:parent_id,
        ...props
      }
    }
  };
};

export const createSelectStorefrontImage = (parent_id, parent_type, templateDataKey, withTemplateId = true, isCarousel = false, data={}, isCarouselBanner = false) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'SelectStorefrontImage',
        parent_id,
        parent_type,
        templateDataKey,
        withTemplateId,
        isCarousel,
        isCarouselBanner,
        data,
      }
    }
  };
};

export const createSupplierBenefitsPopup = (division_id) => {
  return {
    type: DISPLAY_POPUP,
    payload: {
      popup: {
        component: 'SupplierBenefitsPopup',
        division_id
      }
    }
  };
};

export const createEditInventoryBreakdownQuantityPopup = (item, locked) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'EditInventoryBreakdownQuantityPopup',
      item,
      locked
    }
  }
});

export const createChangeCurrencyPopup = (project) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'ChangeCurrencyPopup',
      project
    }
  }
});

export const createAvalaraShopIssuesPopup = (company_id, avalara_issues) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: 'AvalaraShopIssuesPopup',
      company_id,
      avalara_issues
    }
  }
});

export const createNotifyShippingChangesPopup = (purchase_order_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: "NotifyShippingChangesPopup",
      purchase_order_id,
    },
  },
});

export const createLoadReportPopup = (report_type) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: "LoadReportPopup",
      report_type,
    },
  },
});

export const createDeleteReportPopup = (report_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: "DeleteReportPopup",
      report_id,
    },
  },
});

export const createSaveReportPopup = () => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: "SaveReportPopup",
    },
  },
});

export const createRenameTemplatePopup = (public_view_template_id, public_view_template_title, templateNames) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: "RenameTemplatePopup",
      public_view_template_id,
      public_view_template_title,
      templateNames
    },
  },
});

export const createDeleteTemplatePopup = (public_view_template_id, public_view_template_title) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: "DeleteTemplatePopup",
      public_view_template_id,
      public_view_template_title
    },
  },
});

export const createPublishTemplatePopup = (public_view_template_id) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup: {
      component: "PublishTemplatePopup",
      public_view_template_id,
    },
  },
});

export const createEditHelpScreenTextPopup = (shopId) => ({
  type: DISPLAY_POPUP,
  payload: {
    popup:{
      component: 'EditHelpScreenTextPopup',
      shopId
    }
  }
});